import { createApp } from 'vue'
import Vuex from 'vuex';
import ElementPlus from 'element-plus'
import overall from './assets/js/overall.js'//全局挂载
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from'./store/index.js'

// 导入并注册Element Plus图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Xicon from "@/components/Icon.vue";// 自定义图标组件


const app = createApp(App)
app.component('Xicon', Xicon);
// 注册Element Plus
app.use(ElementPlus)
app.use(overall)
app.use(Vuex);
app.use(store)

// 注册Element Plus图标
Object.keys(ElementPlusIconsVue).forEach((key) => {
  app.component(key, ElementPlusIconsVue[key])
})

// 使用路由和挂载应用
app.use(router).mount('#app')