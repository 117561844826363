<template>
	<div class="main" :style="{ backgroundColor: bodyColor }">
		<el-menu
			:default-active="activeIndex"
			:router="true"
			class="el-menu-demo"
			mode="horizontal"
			:ellipsis="false"
			:background-color="backgroundColor"
			:text-color="textColor"
			active-text-color="#ffd04b"
			@select="handleSelect">
			<!-- <el-menu-item index="0">
				<img style="width: 50px" src="@/assets/logo.png" alt="Element logo" />
			</el-menu-item> -->
			<div class="topItem">
				<img class="logo-img" src="@/assets/logo.png" alt="Element logo" />
			</div>

			<el-menu-item index="/">首页</el-menu-item>
			<el-menu-item index="/notes">笔记</el-menu-item>
			<el-menu-item index="/about">关于</el-menu-item>
			<el-sub-menu index="/tools">
				<template #title>工具</template>
				<el-menu-item index="/tools/base64">Base64 编码\解码</el-menu-item>
				<el-menu-item index="/tools/request">在线请求</el-menu-item>
				<!-- <el-sub-menu index="2-4">
					<template #title>item four</template>
					<el-menu-item index="2-4-1">item one</el-menu-item>
					<el-menu-item index="2-4-2">item two</el-menu-item>
					<el-menu-item index="2-4-3">item three</el-menu-item>
				</el-sub-menu> -->
			</el-sub-menu>

			<div class="flex-grow"></div>

			<div class="topItem">
				<div class="avatar"><img src="@/assets/images/avatar.jpg" alt="" /></div>
				<el-popover :width="100" popper-style="background-color: #000; padding: 10px;min-width: 100px;" :show-arrow="false">
					<template #reference>
						<span>{{ userInfo.nickname || userInfo.username || "游客" }}</span>
					</template>
					<template #default>
						<div class="userMenu">
							<button @click="showLogin = true" v-if="!userInfo.nickname && !userInfo.username">登录</button>
							<button @click="logout()" v-if="userInfo.nickname || userInfo.username">退出</button>
						</div>
					</template>
				</el-popover>
			</div>
			<div class="topItem">
				<span>开心每一天</span>
			</div>
		</el-menu>

		<el-drawer v-model="showLogin" direction="rtl" :show-close="false">
			<template #header>
				<h4>登录</h4>
			</template>
			<template #default>
				<div class="loginBox">
					<el-form :model="loginForm" label-width="auto" style="width: 100%">
						<el-form-item>
							<el-input v-model="loginForm.username" style="width: 100%" placeholder="请输入账号" />
						</el-form-item>
						<el-form-item>
							<el-input v-model="loginForm.password" style="width: 100%" placeholder="请输入密码" type="password" show-password />
						</el-form-item>
					</el-form>
					<div class="loginBtn">
						<el-button color="#000" @click="login()" :loading="isLogining">登录</el-button>
						<el-button @click="showLogin = false">取消</el-button>
					</div>
				</div>
			</template>
		</el-drawer>

		<router-view />
	</div>
</template>

<script setup>
	import { ref, computed, watch, onMounted, inject, getCurrentInstance } from "vue";
	const { proxy } = getCurrentInstance(); //proxy相当于vue2的this
	import { useRouter, useRoute } from "vue-router";
	import axios from "axios";
	import { toFormData, toUrlEncodedString } from "../public/js/public.js";
	import { ElNotification, ElMessageBox } from "element-plus"; //消息提醒

	const router = useRouter();
	const route = useRoute();
	let activeIndex = ref(route.path);
	let showLogin = ref(false);
	let isLogining = ref(false);
	let loginForm = ref({ username: "", password: "" });
	let userInfo = ref({});

	let logoutVisible = ref(false);

	let groubConfig = computed(() => {
		if (localStorage.getItem("groubConfig")) {
			return JSON.parse(localStorage.getItem("groubConfig"));
		} else {
			let config = {
				theme: "black",
			};
			localStorage.setItem("groubConfig", JSON.stringify(config));
			return config;
		}
	});
	let backgroundColor = computed(() => {
		let value = "#000";
		if (groubConfig.value.theme) {
			value = getComputedStyle(document.documentElement).getPropertyValue("--" + groubConfig.value.theme + "_menu");
		}
		proxy.$store.dispatch("setBackgroundColor", value);
		return value;
	});
	let textColor = computed(() => {
		let value = "#fff";
		if (groubConfig.value.theme) {
			value = getComputedStyle(document.documentElement).getPropertyValue("--" + groubConfig.value.theme + "_font");
		}
		proxy.$store.dispatch("setTextColor", value);
		return value;
	});
	let bodyColor = computed(() => {
		let value = "#292525";
		if (groubConfig.value.theme) {
			value = getComputedStyle(document.documentElement).getPropertyValue("--" + groubConfig.value.theme + "_body");
		}
		proxy.$store.dispatch("setBodyColor", value);
		return value;
	});

	// 监听route.path的变化
	watch(
		() => route.path,
		(newPath) => {
			activeIndex.value = newPath;
		},
		{ immediate: true } // 立即触发一次回调，确保初始值也被设置
	);

	onMounted(() => {
		getUserInfo();
	});

	console.log("activeIndex", activeIndex, route.path);
	console.log("当前路由信息:", route, router);

	//跳转
	function handleSelect(index) {
		console.log("route", index);
		// router.push(index);
	}

	//登录
	function login() {
		let data = { ...loginForm.value };
		console.log("登录", data);
		axios({
			url: domainName + "/xsyx/users/login",
			method: "post",
			data: toUrlEncodedString(data),
		})
			.then((res) => {
				if (res.data.code == 1) {
					showLogin.value = false;
					ElNotification({
						type: "success",
						message: res.data.msg,
						offset: 50,
					});
					userInfo.value = res.data.data;
					localStorage.setItem("userInfo", JSON.stringify(res.data.data));
					localStorage.setItem("token", res.data.data.token);
				} else {
					ElNotification({
						type: "error",
						message: res.data.msg,
						offset: 50,
					});
				}
			})
			.catch((err) => {
				ElNotification({
					type: "error",
					message: "登录失败,请重试",
					offset: 50,
				});
			});
	}

	// 退出登录
	function logout() {
		ElMessageBox.confirm("确认是否退出登录?", "退出登录", {
			confirmButtonText: "立即退出",
			cancelButtonText: "停留一会",
			type: "info",
			draggable: true,
		})
			.then(() => {
				userInfo.value = {};
				localStorage.removeItem("userInfo");
				localStorage.removeItem("token");
				ElNotification({
					type: "success",
					message: "退出成功",
					offset: 50,
				});
			})
			.catch(() => {});
	}

	// 获取用户信息
	function getUserInfo() {
		let token = localStorage.getItem("token");
		if (!token) {
			localStorage.removeItem("userInfo");
			userInfo.value = {};
			return;
		}
		axios({
			url: domainName + "/xsyx/users/getUserInfo",
			method: "post",
			data: {
				token: token,
			},
		})
			.then((res) => {
				if (res.data.code == 1) {
					userInfo.value = res.data.data;
					localStorage.setItem("userInfo", JSON.stringify(res.data.data));
				} else {
					ElNotification({
						type: "error",
						message: res.data.msg,
						offset: 50,
					});
					localStorage.removeItem("token");
					localStorage.removeItem("userInfo");
					userInfo.value = {};
				}
			})
			.catch((err) => {
				localStorage.removeItem("token");
				localStorage.removeItem("userInfo");
				userInfo.value = {};
			});
	}
</script>

<style lang="less">
	@import url("@/assets/less/theme.less");

	* {
		margin: 0;
		padding: 0;
	}
	.main {
		height: 100vh;
		width: 100vw;
		overflow: hidden;
	}
	/* top */
	.el-menu-demo {
		height: 50px;
		border: 0 !important;
	}
	.topItem {
		height: 100%;
		padding: 0 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
	.logo-img {
		height: 90%;
		cursor: pointer;
	}
	.flex-grow {
		flex-grow: 1;
	}
	.avatar {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		cursor: pointer;
		overflow: hidden;
		margin-right: 10px;

		img {
			height: 100%;
		}
	}
	.userMenu {
		width: 100%;
		button {
			width: 100%;
			border: 0;
			line-height: 30px;
			border-radius: 5px;
			background-color: unset;
			color: #fff;
			cursor: pointer;
		}
		button:hover {
			background-color: #f5f5f5;
			color: #000;
		}
	}

	.loginBox {
		width: 100%;
		height: 80%;
		margin: 10px auto;
		align-content: center;
		flex-wrap: wrap;
		justify-content: center;

		.el-form {
			margin-bottom: 35px;
			.el-form-item {
				margin-bottom: 22px;
			}
		}

		.loginBtn {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}

	/* 自定义滚动条宽度 */
	::-webkit-scrollbar {
		width: 10px; /* 横向滚动条 */
		height: 10px; /* 纵向滚动条 */
	}

	/* 滚动条轨道样式 */
	::-webkit-scrollbar-track {
		background-color: #f1f1f1;
		border-radius: 10px;
		z-index: -10;
	}

	/* 滚动条滑块样式 */
	::-webkit-scrollbar-thumb {
		background-color: #88888880;
		border-radius: 10px;
	}

	/* 鼠标悬停时滚动条滑块样式 */
	::-webkit-scrollbar-thumb:hover {
		background-color: #555555a2;
		cursor: move;
	}
</style>
