 /**
* 将给定对象转换为 FormData格式。
* 对于对象中值为数组的属性，将为数组中的每个元素单独添加对应的表单数据项。
* @param {Object} obj - 待转换的对象，其属性名作为键，属性值作为表单数据值。
* @returns {FormData} - 转换后形成的 FormData 对象。
*/
export function toFormData(obj) {
   const formData = new FormData();
   // 遍历对象中的所有键值对
   Object.keys(obj).forEach((key) => {
       const value = obj[key];
       // 处理值为数组的情况
       if (Array.isArray(value)) {
           // 为数组中的每个元素添加对应的表单数据项
           value.forEach((item) => {
               formData.append(key, item);
           });
       } else {
           // 对于非数组值，直接添加对应的表单数据项
           formData.append(key, value);
       }
   });
   return formData;
}

export function toUrlEncodedString(obj) {
    const params = new URLSearchParams();
    // 遍历对象中的所有键值对
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        // 处理值为数组的情况
        if (Array.isArray(value)) {
            // 为数组中的每个元素添加对应的查询参数
            value.forEach((item) => {
                params.append(key, item);
            });
        } else {
            // 对于非数组值，直接添加对应的查询参数
            params.append(key, value);
        }
    });
    // 返回URL编码的字符串
    return params.toString();
}


/**
 * 获取当前日期，格式为YYYY-MM-DD HH:mm:ss。
 * @returns {string} 返回当前日期的字符串格式，格式为YYYY-MM-DD HH:mm:ss。
 */
export function getCurrentDateTime(time) {
    const now = new Date(time);
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}