/*
 * @Description: Vuex配置
 * @Author: XSYX
 * @Date: 2024-07-31 14:14:44
 * @LastEditors: XSYX
 * @LastEditTime: 2024-07-31 14:24:46
 * @FilePath: \xsyx_vue3\src\store\index.js
 */
import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        // 这里存放应用的全局状态
        backgroundColor: '',
        textColor: '',
        bodyColor: '',
    },
    mutations: {
        // 这里存放更新状态的方法
        setBackgroundColor(state, value) {
            state.backgroundColor = value;
        },
        setTextColor(state, value) {
            state.textColor = value;
        },
        setBodyColor(state, value) {
            state.bodyColor = value;
        }
    },
    actions: {
        // 这里存放异步操作
        setBackgroundColor({ commit }, value) {
            commit('setBackgroundColor', value);
        },
        setTextColor({ commit }, value) {
            commit('setTextColor', value);
        },
        setBodyColor({ commit }, value) {
            commit('setBodyColor', value);
        }
    },
    getters: {
        // 这里存放获取状态的方法
        getBackgroundColor(state) {
            return state.backgroundColor;
        },
        getTextColor(state) {
            return state.textColor;
        },
        getBodyColor(state) {
            return state.bodyColor;
        }
    }
});