import { ElNotification } from 'element-plus';

function message(type, message, duration = 1000) {
    ElNotification({
        type: type,// 提示类型
        message: message,// 提示信息
        duration: duration,// 显示时间
        offset: 50,// 距离顶部的距离
    });
};

export default {
    install: function (app) {
        app.config.globalProperties.$message = message
    },
};