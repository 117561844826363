/*
 * @Description: 项目路由
 * @Author: XSYX
 * @Date: 2024-05-09 12:39:48
 * @LastEditors: XSYX
 * @LastEditTime: 2024-07-31 10:45:56
 * @FilePath: \xsyx_vue3\src\router\index.js
 */
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import('../views/Notes.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/tools',
    name: 'tools',
    children: [
      {
        path: 'base64',
        name: 'base64',
        component: () => import('../views/Tools/Base64.vue'),
      },
      {
        path: 'request',
        name: 'request',
        component: () => import('../views/Tools/Request.vue'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
